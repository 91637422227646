import 'regenerator-runtime/runtime';
const axios = require('axios');
import SessionService from './SessionService';
const sessionService = new SessionService();

export default class DataService {
	constructor() {
		this.AUTH_INFO = { headers: { Authorization: `Bearer ${sessionService.getIdToken()}` } };
		this.API_URL = 'https://api.spuren-in-herten.de';
		this.CACHE_TTL_IN_MINUTES = 1;
	}

	async list(type) {
		var data = this.getFromCache(type);

		if (data) {
			return data;
		}

		var list = await axios.get(`${this.API_URL}/${type}`);
		console.log('getting from api:', type);
		if (list && list.data && list.data.length > 0) {
			list.data = list.data.filter(function (item) {
				return item.status === 'Veröffentlicht/geplant';
			});

			if (type === 'news') {
				list.data.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));

				list.data = list.data.filter(function (item) {
					if (item.startDate) {
						let dateParts = item.startDate.split('-'); // 2021-11-31
						let date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]), 0, 0, 0, 0);
						return date.getTime() < new Date().getTime();
					}
					return true;
				});

				list.data = list.data.filter(function (item) {
					if (item.endDate) {
						let dateParts = item.endDate.split('-'); // 2021-11-31
						let date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]), 0, 0, 0, 0);

						return date.getTime() > new Date().getTime();
					}
					return true;
				});
			}

			this.putToCache(type, list.data);
		}

		return list.data;
	}

	async get(type, id) {
		var list = await this.list(type);

		if (list) {
			var item = list.find((obj) => {
				return obj.id === id;
			});
			if (item) {
				return item;
			}
		}

		//console.log('getting', type, id);
		var data = await axios.get(`${this.API_URL}/${type}/${id}`);

		return data.data;
	}

	putToCache(key, value) {
		const now = new Date();

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + this.CACHE_TTL_IN_MINUTES * 60 * 1000,
		};
		console.log('adding to cache:', key, item);
		sessionStorage.setItem(key, JSON.stringify(item));
	}

	getFromCache(key) {
		const itemStr = sessionStorage.getItem(key);

		// if the item doesn't exist, return null
		if (!itemStr) {
			return null;
		}
		const item = JSON.parse(itemStr);
		const now = new Date();
		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			sessionStorage.removeItem(key);
			return null;
		}
		console.log('getting from cache:', key);
		return item.value;
	}

	purgeCache(key) {
		sessionStorage.removeItem(key);
	}
}
