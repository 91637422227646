import 'bootstrap';
//import 'babel-polyfill';
import SessionService from './SessionService';
import AnalysisService from './AnalysisService';
import InstallService from './InstallService';
import Layout from './Layout';
import DataService from './DataService';
const sessionService = new SessionService();
const analysisService = new AnalysisService();
const installService = new InstallService();
const layout = new Layout();

// console.log('App.js (sync)');

// localStorage.removeItem('locations');
// localStorage.removeItem('contents');
// localStorage.removeItem('news');

if ('serviceWorker' in navigator && window.location.host !== 'localhost:1234') {
	navigator.serviceWorker.register('../sw.js');
}

layout.init();
sessionService.handleSession();

(async () => {
	installService.setupA2HS();
	// pre-fetch whole content asynchronously
	const dataService = new DataService();
	// dataService.list('locations');
	// dataService.list('contents');
	// dataService.list('news');
	const welcomeText = document.getElementById('welcomeText');

	if (welcomeText) {
		const data = await dataService.get('contents', '1cbc0259-0849-4595-9778-87efd725f95c');

		welcomeText.innerHTML = data.excerpt;
		Promise.all([dataService.list('locations'), dataService.list('contents'), dataService.list('news')]);
	}

	analysisService.log();
})();
