import Layout from './Layout';
const layout = new Layout();
export default class SessionService {
	constructor() {}

	log() {
		navigator.sendBeacon(
			'https://api.spuren-in-herten.de/analysis',
			JSON.stringify({
				page: window.location.pathname,
				page_id: window.location.hash.split('#')[1],
				id_token: localStorage.getItem('ID_TOKEN'),
				device_isPwa: layout.isPwa(),
				device_isMobile: layout.isMobile(),
				//device_ua: navigator.userAgent,
				device_vendor: navigator.vendor,
				device_width: window.screen.width,
				device_height: window.screen.height,
				app_version: '373',
			})
		);
	}
}
