import Layout from './Layout';
import $ from 'jquery';
import 'bootstrap';
const layout = new Layout();
export default class InstallService {
	constructor() {}

	showInstallWidget(a2hs) {
		let installWidget = document.getElementById('installWidget');

		if (installWidget) {
			installWidget.style.display = 'block';
		}
		console.log('Installation möglich. A2HS:', a2hs);
	}
	showNewsWidget() {
		let newsWidget = document.getElementById('newsWidget');

		if (newsWidget) {
			newsWidget.style.display = 'block';
		}
	}

	hideInstallWidget() {
		let installWidget = document.getElementById('installWidget');

		if (installWidget) {
			installWidget.style.display = 'none';
		}
	}

	setupA2HS() {
		if (!layout.isPwa()) {
			let deferredPrompt;

			let installButton = document.getElementById('installButton');
			let dismissButton = document.getElementById('dismissButton');

			window.addEventListener('beforeinstallprompt', (e) => {
				this.showInstallWidget(true);
				// Prevent the mini-infobar from appearing on mobile
				e.preventDefault();
				// Stash the event so it can be triggered later.
				deferredPrompt = e;
			});

			if (layout.isiOS()) {
				this.showInstallWidget(false);
			}

			if (installButton) {
				installButton.addEventListener('click', async () => {
					if (deferredPrompt) {
						// Hide the app provided install promotion
						//hideInstallPromotion();
						// Show the install prompt
						deferredPrompt.prompt();
						// Wait for the user to respond to the prompt
						const { outcome } = await deferredPrompt.userChoice;
						// Optionally, send analytics event with outcome of user choice
						console.log(`User response to the install prompt: ${outcome}`);
						// We've used the prompt, and can't use it again, throw it away
						deferredPrompt = null;
					} else {
						if (layout.isiOS()) {
							let toolTipHolder = $('.tooltipHolder');
							toolTipHolder.show();
							$('.blurry-overlay')
								.show()
								.on('click', function () {
									//$('.tooltip').hide();
									$('.blurry-overlay').hide();
									toolTipHolder.tooltip('hide').hide();
								});

							//console.log(toolTipHolder.html());
							toolTipHolder
								.tooltip({
									html: true,
									title: toolTipHolder.html(),
									placement: 'top',
								})
								.tooltip('show')
								.on('shown.bs.tooltip', function () {
									// $('#newsWidget').show();
									// $('#installWidget').hide();
									$('.tooltip-inner').html(toolTipHolder.html());
								});
						} else {
							this.showNewsWidget();
							alert('Installation von Ihrem Gerät nicht unterstützt');
						}
					}
				});
			}
			if (dismissButton) {
				dismissButton.addEventListener('click', async () => {
					this.hideInstallWidget();
					this.showNewsWidget();
				});
			}
		}
	}
}
