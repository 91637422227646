import jwt_decode from 'jwt-decode';

export default class SessionService {
	handleSession() {
		if (this.getIdToken() || window.location.host === 'localhost:1234') {
			// logged in
		} else {
			//window.location = '/login.html';
		}
	}

	storeIdToken(ID_TOKEN) {
		var decoded = jwt_decode(ID_TOKEN);
		sessionStorage.setItem('ID_TOKEN', ID_TOKEN);
		sessionStorage.setItem('EMAIL', decoded.email);
	}

	getIdToken() {
		if (window.location.hash === '#debug') {
			sessionStorage.setItem('ID_TOKEN', 'debug');
		}
		return sessionStorage.getItem('ID_TOKEN');
	}

	getLoginUrl() {
		const CLIENT_ID = '1v1na3jdanbhqrhb9nm2ut09od';
		const REDIRECT_URL = `${window.location.origin}/login.html`;
		const LOGIN_URL = 'https://herten.auth.eu-central-1.amazoncognito.com/login';
		const PARAMS = `?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${REDIRECT_URL}`;
		return LOGIN_URL + PARAMS;
	}
}
